<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Student Bill Due Report
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
              labelExcel="Download Excel"
              labelPdf="Download Pdf"
            ></print-buttons>
            <!-- 
               -->
            <v-btn
              v-if="form.items.data.length"
              :disabled="selectedStudents.length < 1"
              @click="sendSMSModal = true"
              color="primary"
              small
              outlined
              ><v-icon class="mr-1" small>message</v-icon> Send SMS ({{
                selectedStudents.length
              }})</v-btn
            >
          </v-card-title>

          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  :disabled="!batches.length"
                  :items="[{ value: 'all', text: 'All' }, ...getBatches]"
                  class="pa-0"
                  label="Batch"
                  v-model="batchId"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-select
                  :disabled="gradeLoading || batchId === 'all'"
                  :loading="gradeLoading"
                  :items="[{ value: 'all', text: 'All' }, ...grades]"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>

              <v-flex xs4 sm4>
                <search-button
                  style=""
                  :disabled="!batchId && !grade"
                  permission="billing-report-collection"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <div v-if="form.items.data.length > 0" class="data-represent">
            <div>
              <span class="d-info"></span>&nbsp;
              <strong>{{ form.items.meta.total }}</strong>
              <small> Students</small>
            </div>
            <div>
              <span class="d-primary"></span> &nbsp;
              <strong>{{ selectedStudents.length }}</strong>
              <small> Selected Records</small>
            </div>
            <div>
              <span class="d-success"></span> &nbsp;
              <strong>{{ totalSum.currency() }}</strong>
              <small> Total Due</small>
            </div>
          </div>
          <br />

          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :loading="form.loading"
          >
            <template v-slot:header.select>
              <v-checkbox
                :disabled="grade === 'all'"
                v-if="form.items.data.length > 0"
                class="ml-0"
                primary
                :input-value="
                  form.items.data.length === selectedStudents.length &&
                    selectedStudents.length !== 0
                "
                :indeterminate="
                  selectedStudents.length !== 0 &&
                    selectedStudents.length < form.items.data.length
                "
                @click.stop="toggleAllStudent"
                hide-details
              ></v-checkbox>
              <span v-else>##</span>
            </template>
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  <v-checkbox
                    :disabled="batchId === 'all' || grade === 'all'"
                    v-if="true"
                    :input-value="selectedStudents.includes(item)"
                    primary
                    hide-details
                    @click.stop="selectStudent(item)"
                  >
                  </v-checkbox>
                </td>
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.enroll_code }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.due_amount.currency() }}</strong>
                </td>
                <td class="text-right">
                  <span class="mt-2">
                    {{
                      item.guardian_name && item.guardian_name !== "null"
                        ? item.guardian_name
                        : "-"
                    }}
                    <p
                      v-if="
                        item.guardian_mobile && item.guardian_mobile !== 'null'
                      "
                    >
                      <strong>({{ item.guardian_mobile }})</strong>
                    </p>
                  </span>
                </td>
              </tr>
            </template>
            <template v-slot:body.append v-if="form.items.data.length">
              <tr style="background-color:#eee;">
                <td :colspan="4" class="text-xs-left">
                  <strong>Total:</strong>
                </td>
                <td width="200" colspan="2" class="text-xs-left">
                  <strong>{{ totalSum.currency() }}</strong> <br />
                  {{ convertNumberToWords(totalSum) }}
                </td>
                <!--<td class="text-xs-center"></td>-->
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="sendSMSModal" scrollable persistent max-width="1000px">
      <v-card outlined class="primary--border">
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text>
          <br />
          <alert-message
            type="info"
            :extraSmall="true"
            :title="`Send Bulk SMS Alert`"
          >
            <template v-slot:message>
              When using dynamic placeholders the below statistics can only be
              used for general idea and hence cannot be fully trusted. If you
              want to get the exact calculation please
              <a href="#"><u> Click here</u></a>
            </template>
          </alert-message>
          <div class="mt-2">
            <br />
            <v-sms-input-field
              :show-alert="false"
              :total-sms="selectedStudents.length"
              @message="message"
              :default-placeholder="[
                {
                  text: 'Guardian Name',
                  placeholder: '{{guardian_name}}',
                },
                {
                  text: 'Student Name',
                  placeholder: '{{student_name}}',
                },

                {
                  text: 'Guardian Mobile',
                  placeholder: '{{guardian_mobile}}',
                },
                {
                  text: 'Due Amount',
                  placeholder: '{{due_amout}}',
                },
              ]"
              :default-sms="``"
            ></v-sms-input-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="sendSMSModal = false"
            >Close</v-btn
          >
          <v-btn
            @click="sendSMSNow"
            :disabled="sms.length < 1 || (my_sms && my_sms.balance < 1)"
            color="success"
            :loading="sendingSMS"
            text
            >Save
          </v-btn>

          <!--<load-button  @action="store" color="success" text >Save</load-button>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { convertNumberToWords } from "../../../../library/helpers";

export default {
  mixins: [Mixins],
  data: () => ({
    sms: "",
    my_sms: "",
    sendSMSModal: false,
    sendingSMS: false,
    selectedStudents: [],
    gradeLoading: false,
    form: new Form(
      {
        bill_month: "",
        grade_id: "",
        enroll: "",
      },
      "/api/report/student-due"
    ),
    search: null,
    pagination: {
      itemsPerPage: 2000,
    },
    batchId: "",
    totalSum: 0,
    headers: [
      { text: "#", align: "left", value: "select", width: 3, sortable: false },

      {
        text: "#",
        align: "left",
        value: "id",
        sortable: false,
        width: 20,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      //   { text: "Roll", align: "left", value: "roll", sortable: false },
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll_code",
        sortable: false,
      },
      {
        text: "Due Amount",
        align: "left",
        value: "due_amount",
        sortable: false,
      },
      {
        text: "Guardian",
        align: "right",
        value: "guardian_name",
        sortable: false,
      },
    ],
    grades: [],
    grade: "",
    sections: [],
    section: "",
    collection: [],
    downloadUrl: "",
  }),

  computed: {
    ...mapState(["batch", "batches"]),
    getBatches() {
      let batchList = [];
      //   let batchList = [{ text: "All", value: "" }];
      this.batches.map((item) => {
        batchList.push({ text: item.name, value: item.id });
      });
      return batchList;
    },
  },

  mounted() {
    this.getGrades();
    let batchId = null;
    this.batches.map((item) => {
      batchId = item.id;
    });
    this.batchId = batchId;
  },

  watch: {
    batchId: function(val) {
      this.form.items.data = [];

      // this.grade = "";
      if (val === "all") {
        this.grade = "all";
      }
    },
    grade: function(value) {
      this.form.items.data = [];
      //   this.section = "";
      //   if (this.grade !== "all") this.getSection();
    },
    batches: function(value) {
      let batchId = null;
      value.map((item) => {
        batchId = item.id;
      });
      this.batchId = batchId;
    },
  },

  methods: {
    convertNumberToWords,
    message({ message, stats, my_sms }) {
      this.sms = message;
      this.my_sms = my_sms;
    },

    sendSMSNow() {
      if (!this.sms || this.selectedStudents.length < 1) return;

      let payload = [];
      this.selectedStudents.map((item) => {
        if (item.guardian_mobile) {
          const msg = this.sms
            .replace("{{guardian_name}}", item.guardian_name)
            .replace("{{student_name}}", item.name)
            .replace("{{guardian_mobile}}", item.guardian_mobile)
            .replace("{{due_amout}}", item.due_amount);
          payload.push({ message: msg, mobile: item.guardian_mobile });
        }
      });

      this.sendingSMS = true;
      this.$rest
        .post("/api/sms/quick-send-bulk", { payload })
        .then((res) => {
          this.$events.fire("notification", {
            message: "SMS was delivered successfully",
            status: "success",
          });
          this.sendSMSModal = false;
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {
          this.sendingSMS = false;
        });
    },
    toggleAllStudent() {
      if (
        this.selectedStudents.length > 0 &&
        this.selectedStudents.length < this.form.items.data.length
      ) {
        this.selectedStudents = this.form.items.data;
      } else if (
        this.selectedStudents.length > 0 &&
        this.selectedStudents.length === this.form.items.data.length
      ) {
        this.selectedStudents = [];
      } else {
        this.selectedStudents = [];
        this.form.items.data.map((item) => {
          this.selectedStudents.push(item);
        });
      }
    },
    selectStudent(item) {
      if (item)
        if (this.selectedStudents.indexOf(item) > -1)
          this.selectedStudents.splice(this.selectedStudents.indexOf(item), 1);
        else this.selectedStudents.push(item);
    },
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&gradeId=" +
        this.grade +
        "&sectionId=" +
        this.section +
        "&batchId=" +
        this.batchId
      );
    },

    get(params) {
      if (!this.grade && !this.batchId) return;

      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.selectedStudents = [];
      this.form.get(null, query).then(({ data }) => {
        this.downloadUrl = data.download_url;
        this.totalSum = 0;
        data.data.map((res) => {
          this.totalSum += parseFloat(res.due_amount);
        });
        this.pagination.totalItems = data.meta.total;
      });
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          let $this = this;
          this.grades = [];
          //   this.grades = [{ value: "all", text: "ALL" }];

          data.data.map((item) => {
            this.grades.push({ value: item.id, text: item.name });
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getSection() {
      if (this.grade) {
        this.$rest.get("/api/section?grade=" + this.grade).then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 0;
}
</style>
